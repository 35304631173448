/**
change the width of the dialog fenster
attention:variable(--mat-dialog-container-max-width) is changed to 1000px,too
can find in styles.svss under class cp2-theme
**/
// .mat-mdc-dialog-panel {
//     width: 1000px;
// }

/** change the display of the dialog fenster**/
.dialog-patient-container {
    .mat-mdc-form-field {
        display: block;
        color: var(--color-white);
    }
}

//style for the  dialog in patient overview
/* Size L- Styles for iPad Mini (1180px x 820px) */
@media (min-width: 768px) and (max-width: 1023.9px) {
    .patient-overview-dialog-container {
        width: 700px !important;
        height: 500px;
    }
}

/*Size L- Styles for iPad Pro (1366px x 1024px) */
@media (min-width: 1024px) and (max-width: 1366.9px) {
    .patient-overview-dialog-container {
        width: 810px !important;
        height: 642px;
    }
}

/*Size L Styles for Desktop (1920px x 1080px) */
@media (min-width: 1367px) and (max-width: 1920px) {
    .patient-overview-dialog-container {
        width: 1138px !important;
        height: 658px;
    }
}

.patient-overview-dialog-container {
    width: 1138px !important;
    height: 658px;
}
