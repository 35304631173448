.patient-visit-info-button {
    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        .mat-icon{
            overflow: unset
        }
    }
}
