.workflow-chips {
    /**change the color of bg in the all chips in workflow*/

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--color-background-primary-color);
    }
    /**change the color of bg in the selected chip*/

    .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
            .mdc-evolution-chip--disabled
        ) {
        background-color: var(--primary-color);
    }
    /**change the color of text in the selected chip*/

    .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
            .mdc-evolution-chip--disabled
        )
        .mdc-evolution-chip__text-label {
        color: var(--color-white);
    }
    /**change the color of icon in the selected chip*/
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
        .mdc-evolution-chip__checkmark {
        color: var(--color-white);
    }
}
