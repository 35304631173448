formio input[type="text"],
formio textarea,
formio select,
formio input[type="email"],
formio input[type="number"],
formio input[type="password"],
formio input[type="date"],
formio input[type="datetime-local"],
formio input[type="month"],
formio input[type="search"],
formio input[type="tel"],
formio input[type="time"],
formio input[type="url"],
formio input[type="week"] {
    border-radius: 4px; /* Material Design border radius */
    padding: 0.3em 0.2em;
    font-size: 1em; /* Material Design font size */
    line-height: 1.5; /* Material Design line height */
    color: var(--formio-text-color, #000); /* Material Design text color */
    background-color: #fff; /* Material Design background color */
    border: var(
        --formio-input-field-border
    ); /* Material Design border style, slightly darker for better contrast */
    box-shadow: none; /* Remove any default box-shadow */
    outline: none; /* Remove default focus outline */
    width: 100%;
    box-sizing: border-box;
}

/* style the buttons in the dialog */
.formio-submit-cancel-buttons {
    .example-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 1em;
        button:nth-child(2) {
            color: var(--color-link-primary-color);
            background-color: var(--color-white);
            border: 1px solid var(--color-link-primary-color);
        }
    }
}

/* set the width of the luic datetimepicker input area */
form {
    luic-datetimepicker-input {
        width: 100%;
        display: block;
        mat-form-field {
            width: 100%;
        }
    }
}

formio .formio-choices {
    border-radius: 4px; /* Material Design border radius */
    font-size: 1em; /* Material Design font size */
    line-height: 1.5; /* Material Design line height */
    color: var(--formio-text-color, #000); /* Material Design text color */
    background-color: #fff; /* Material Design background color */
    border: var(
        --formio-input-field-border
    ); /* Material Design border style, slightly darker for better contrast */
    width: 100%;
    box-sizing: border-box;
}

formio label {
    color: var(--formio-text-color);
    font-weight: 300;

    &[ref="label"] {
        font-weight: 400;
    }
}

formio fieldset {
    border: var(--formio-input-field-border);
}

formio .formio-submit-cancel-buttons {
    fieldset {
        border: none;
    }

    [ref="nested-buttons"] {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

// Separate the different elements of the form
formio [ref="component"] {
    margin-top: 1em;
}

formio input[type="text"]:focus,
formio textarea:focus,
formio select:focus,
formio input[type="email"]:focus,
formio input[type="number"]:focus,
formio input[type="password"]:focus,
formio input[type="date"]:focus,
formio input[type="datetime-local"]:focus,
formio input[type="month"]:focus,
formio input[type="search"]:focus,
formio input[type="tel"]:focus,
formio input[type="time"]:focus,
formio input[type="url"]:focus,
formio input[type="week"]:focus {
    border-color: #3f51b5; /* Material Design focus color (e.g., Indigo 500) */
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25); /* Material Design-like focus glow */
}

formio select {
    -webkit-appearance: none; /* Remove default styling in WebKit browsers */
    -moz-appearance: none; /* Remove default styling in Firefox */
    appearance: none; /* Remove default arrow in IE */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%233f51b5" d="M7 10l5 5 5-5z"/></svg>'); /* Custom arrow icon in Material Design Indigo 500 color */
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 24px;
}

/* Adjustments for checkbox and radio buttons to align more closely with Material Design */
formio input[type="checkbox"],
formio input[type="radio"] {
    accent-color: #3f51b5; /* Material Design Indigo 500 for checkbox and radio */
}

/* Styling for Form.io buttons to match Material Design */
formio button {
    background-color: var(
        --formio-button-background-color
    ); /* Material Design Indigo 500 background */
    color: white; /* White text color */
    padding: 8px 16px; /* Material Design button padding */
    border: none; /* Remove border */
    border-radius: var(--formio-button-border-radius);
    text-transform: none; /* Material Design button text style */
    font-size: 0.875em; /* Smaller font size for buttons */
    letter-spacing: 1.25px; /* Letter spacing for uppercase text */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.btn-secondary {
    color: var(--formio-secondary-button-color);
    background: var(--formio-secondary-button-background-color);
}

formio button:hover {
    opacity: 7;
}

formio .selection.dropdown {
    padding: 0.3em 0.2em;
    border: 1px solid gray;
    border-radius: 0.2em;
}

//#region for task formular: style for task formular
// form {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1rem;

//     mat-form-field,
//     .task-title {
//         width: 96% !important;
//     }
//     mat-form-field,
//     .task-details {
//         width: 96% !important;
//     }
//     .task-priority {
//         width: 30% !important;
//     }
//     .task-contractor {
//         width: 30% !important;
//     }
//     .task-done {
//         width: 30% !important;
//     }
//     .task-goalDate-goalDateOn {
//         width: 60% !important;
//     }
//     .task-goalDate {
//         width: 30% !important;
//     }
//     .task-goalDateOn {
//         width: 50% !important;
//     }
//     .formio-submit-cancel-buttons {
//         position: sticky !important;
//         bottom: 0 !important;
//         background-color: var(--mdc-elevated-card-container-color) !important;
//         margin: 0 !important;
//     }
// }
// @media (max-width: 600px) {
//     form {
//         display: block;
//         .task-title {
//             width: 100% !important;
//         }
//         .task-details {
//             width: 100% !important;
//         }
//         .task-priority {
//             width: 100% !important;
//         }
//         .task-contractor {
//             width: 100% !important;
//         }
//         .task-done {
//             width: 100% !important;
//         }
//         .task-goalDate-goalDateOn {
//             width: 100% !important;
//         }
//         .task-goalDate {
//             width: 100% !important;
//         }
//         .task-goalDateOn {
//             width: 100% !important;
//         }
//     }
// }
.task-formular {
}

//endregion

.dialog-container {
    .mat-mdc-card-content:last-child {
        padding-bottom: 0;
    }
}
.formio-submit-cancel-buttons {
    position: sticky !important;
    bottom: 0 !important;
    background-color: var(--mdc-elevated-card-container-color) !important;
    margin: 0 !important;
}
