@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/google-fonts/Roboto-Medium.woff2) format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    src: url(../../assets/google-fonts/Roboto-Black.woff2) format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    src: url(../../assets/google-fonts/Roboto-Light.woff2) format("woff2");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    src: url(../../assets/google-fonts/Roboto-MediumItalic.woff2) format("woff2");
    font-weight: 400;
}
