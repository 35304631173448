.setting-anonym-data {
    .mdc-form-field--align-end > label {
        margin-left: 0 !important;
        font-weight: 400;
        font-size: var(--mat-expansion-header-text-size);
    }
    .mdc-form-field > label {
        margin-left: 0;
    }
    .mat-internal-form-field {
        width: 100%;
        justify-content: space-between;
    }
}
