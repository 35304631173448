@use "../basic/mixin" as *;
@use "../basic/variables" as var;

.orga-container {
    .mat-mdc-tab-header {
        background-color: var(--color-white);
        .mat-mdc-tab-labels {
            width: 70%;
        }
    }
}

.orga-sort-search {
    //set the bg color if the sort and search fields
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: var(--color-white);
    }
}

@media (max-width: 1024px) {
    .orga-container {
        .mat-mdc-tab-header {
            background-color: var(--color-white);
            width: initial;
        }
    }
}
