.workflow-container {
    .mat-mdc-tab-header {
        background-color: var(--color-white);

        .mat-mdc-tab-labels {
            width: 70%;
        }
    }
    .mat-mdc-tab-body-content {
        // height: auto;
        overflow: hidden;
    }
}
.workflow-sort-search {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: var(--color-white)
    }
}
@media (max-width: 1024px) {
    .workflow-container {
        .mat-mdc-tab-header {
            background-color: var(--color-white);
            width: 60%;


        }
    }
}
