@use "../basic/mixin" as *;
@use "../basic/variables" as vars;

#home-wrapper {
    .list-dashboard {
        //change the mat-select the color of the triangle icon
        .mat-mdc-select-arrow {
            color: var(--color-white);
        }
    }
}

//change the bg color for the sort and search input
.patient-list-sort {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
}
.patient-list-search {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
}
.patient-list-dropdown {
    .mdc-list-item__primary-text {
        text-overflow: initial !important;
        white-space: initial;
    }
}
